// Copyright (C) 2017-2023 Smart code 203358507

const React = require("react");
const PropTypes = require("prop-types");
const classnames = require("classnames");
const { default: Icon } = require("@stremio/stremio-icons/react");
const {
	Button,
	Image,
	PlayIconCircleCentered,
	useProfile,
	platform,
	useStreamingServer,
	useToast,
} = require("stremio/common");
const { useServices } = require("stremio/services");
const StreamPlaceholder = require("./StreamPlaceholder");
const styles = require("./styles");

const Stream = ({
	className,
	videoId,
	videoReleased,
	addonName,
	name,
	description,
	thumbnail,
	progress,
	deepLinks,
	...props
}) => {
	const profile = useProfile();
	const streamingServer = useStreamingServer();
	const { core } = useServices();
	const toast = useToast();
	const href = React.useMemo(() => {
		const haveStreamingServer =
			streamingServer.settings !== null &&
			streamingServer.settings.type === "Ready";
		return deepLinks
			? profile.settings.playerType &&
			  profile.settings.playerType !== "internal"
				? platform.isMobile() || !haveStreamingServer
					? (deepLinks.externalPlayer.openPlayer || {})[platform.name] ||
					  deepLinks.externalPlayer.href
					: null
				: typeof deepLinks.player === "string"
				? deepLinks.player
				: null
			: null;
	}, [deepLinks, profile, streamingServer]);
	const markVideoAsWatched = React.useCallback(() => {
		if (typeof videoId === "string") {
			core.transport.dispatch({
				action: "MetaDetails",
				args: {
					action: "MarkVideoAsWatched",
					args: [{ id: videoId, released: videoReleased }, true],
				},
			});
		}
	}, [videoId, videoReleased]);
	const onClick = React.useCallback(
		(e) => {
			e.preventDefault(); // prevent default to ensure the custom logic works
			if (deepLinks.externalPlayer.href.startsWith("magnet")) {
				let decoded = decodeURIComponent(deepLinks.player);
				let decoded_json_string = Buffer.from(
					"/" + decoded.split("/")[3],
					"base64"
				).toString();

				let e = decoded_json_string.split('"infoHash":"')[1];
				e = e.substring(0, e.indexOf('"'));

				let t = decoded_json_string.split('"fileIdx":')[1];
				t = t.substring(0, t.indexOf(","));

				let r = streamingServer.settings.transportUrl;
				window.location.href = "infuse" + r.split("http")[1] + e + "/" + t;
			} else {
				let lc =
					"infuse" +
					Buffer.from(
						deepLinks.externalPlayer.href.split("base64,")[1].split('"')[0],
						"base64"
					)
						.toString()
						.split("https")[1];

				if (lc.includes("torrentio.strem.fun/realdebrid/")) {
					lc = lc.substring(0, lc.lastIndexOf("/"));
				}

				window.location.href = lc;
			}
		},
		[deepLinks, streamingServer.settings]
	);
	const forceDownload = React.useMemo(() => {
		// we only do this in one case to force the download
		// of a M3U playlist generated in the browser
		return href === deepLinks.externalPlayer.href
			? deepLinks.externalPlayer.fileName
			: false;
	}, [href]);
	const renderThumbnailFallback = React.useCallback(
		() => (
			<Icon className={styles["placeholder-icon"]} name={"ic_broken_link"} />
		),
		[]
	);
	return (
		<Button
			href={href}
			download={forceDownload}
			{...props}
			onClick={onClick}
			className={classnames(className, styles["stream-container"])}
			title={addonName}
		>
			{typeof thumbnail === "string" && thumbnail.length > 0 ? (
				<div
					className={styles["thumbnail-container"]}
					title={name || addonName}
				>
					<Image
						className={styles["thumbnail"]}
						src={thumbnail}
						alt={" "}
						renderFallback={renderThumbnailFallback}
					/>
				</div>
			) : (
				<div
					className={styles["addon-name-container"]}
					title={name || addonName}
				>
					<div className={styles["addon-name"]}>{name || addonName}</div>
				</div>
			)}
			<div className={styles["info-container"]} title={description}>
				{description}
			</div>
			<PlayIconCircleCentered className={styles["play-icon"]} />
			{progress !== null && !isNaN(progress) && progress > 0 ? (
				<div className={styles["progress-bar-container"]}>
					<div
						className={styles["progress-bar"]}
						style={{ width: `${Math.min(progress, 1) * 100}%` }}
					/>
				</div>
			) : null}
		</Button>
	);
};

Stream.Placeholder = StreamPlaceholder;

Stream.propTypes = {
	className: PropTypes.string,
	videoId: PropTypes.string,
	videoReleased: PropTypes.instanceOf(Date),
	addonName: PropTypes.string,
	name: PropTypes.string,
	description: PropTypes.string,
	thumbnail: PropTypes.string,
	progress: PropTypes.number,
	deepLinks: PropTypes.shape({
		player: PropTypes.string,
		externalPlayer: PropTypes.shape({
			href: PropTypes.string,
			fileName: PropTypes.string,
			streaming: PropTypes.string,
			openPlayer: PropTypes.shape({
				choose: PropTypes.shape({
					ios: PropTypes.string,
					android: PropTypes.string,
					windows: PropTypes.string,
					macos: PropTypes.string,
					linux: PropTypes.string,
				}),
				vlc: PropTypes.shape({
					ios: PropTypes.string,
					android: PropTypes.string,
					windows: PropTypes.string,
					macos: PropTypes.string,
					linux: PropTypes.string,
				}),
				outplayer: PropTypes.shape({
					ios: PropTypes.string,
					android: PropTypes.string,
					windows: PropTypes.string,
					macos: PropTypes.string,
					linux: PropTypes.string,
				}),
				infuse: PropTypes.shape({
					ios: PropTypes.string,
					android: PropTypes.string,
					windows: PropTypes.string,
					macos: PropTypes.string,
					linux: PropTypes.string,
				}),
				justplayer: PropTypes.shape({
					ios: PropTypes.string,
					android: PropTypes.string,
					windows: PropTypes.string,
					macos: PropTypes.string,
					linux: PropTypes.string,
				}),
				mxplayer: PropTypes.shape({
					ios: PropTypes.string,
					android: PropTypes.string,
					windows: PropTypes.string,
					macos: PropTypes.string,
					linux: PropTypes.string,
				}),
			}),
		}),
	}),
	onClick: PropTypes.func,
};

module.exports = Stream;
